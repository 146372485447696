/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FormModule } from '../../models/form-module';
import { FormRequestType } from '../../models/form-request-type';
import { HistoryStatisticDto } from '../../models/history-statistic-dto';
import { Interval } from '../../models/interval';

export interface GetHistory$Params {
  interval?: Interval;

/**
 * Defaults to the customer of the current user. If another customer is requested, requires explicit permissions to this customer. The duration must not exceed 366 days.
 */
  customerId?: any;
  module?: FormModule;
  requestType?: FormRequestType;
  from?: string;
  to?: string;
}

export function getHistory(http: HttpClient, rootUrl: string, params?: GetHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<HistoryStatisticDto>> {
  const rb = new RequestBuilder(rootUrl, getHistory.PATH, 'get');
  if (params) {
    rb.query('interval', params.interval, {});
    rb.query('customerId', params.customerId, {});
    rb.query('module', params.module, {});
    rb.query('requestType', params.requestType, {});
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HistoryStatisticDto>;
    })
  );
}

getHistory.PATH = '/statistics/connection-requests/history';
