<div class="dso-card">
  <h5 class="section-heading one-mt-3">
    {{ 'REQUESTS_DETAILS.UPLOADED_DOCUMENTS_TITLE' | translate }}
  </h5>
  <div class="documents-list">
    @for (document of documents(); track document.id) {
      <dso-document
        [connectionRequestId]="currentRequest()?.id || ''"
        [document]="document" />
    } @empty {
      <div class="documents-list-empty">
        <mat-icon
          class="dso-icon--small"
          svgIcon="info"
          [attr.aria-label]="
            'REQUESTS_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate
          " />
        <p>{{ 'REQUESTS_DETAILS.NO_DOCUMENTS_AVAILABLE' | translate }}</p>
      </div>
    }
  </div>

  <h5 class="section-heading one-mt-4">
    {{ 'REQUESTS_DETAILS.UPLOAD_ADDITIONAL_DOCUMENTS_TITLE' | translate }}
  </h5>
  <p>
    {{ 'REQUESTS_DETAILS.SUPPORTED_FILE_TYPES' | translate }}
  </p>
  <dso-documents-upload [connectionRequestId]="currentRequest()?.id ?? ''" />
</div>
