import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import {
  DateAdapter,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, Router, TitleStrategy } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '@environments/index';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { routes } from './app.routes';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  CustomDateAdapter,
  EnvironmentService,
  IconInitializerService,
} from './core/services';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ApiModule } from 'src/api/dso-portal/generated/api.module';
import {
  DEFAULT_APP_LANGUAGE,
  loadingInterceptor,
  MultiLanguageTitleStrategy,
} from './core/utils';
import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';

function initializeApp(
  environmentService: EnvironmentService,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration
): () => void {
  return () => {
    googleTagManagerConfiguration.set({
      id: environmentService.gtmId,
      gtm_preview: environmentService.gtmPreview,
      gtm_auth: environmentService.gtmAuth,
    });
  };
}
// fix for https://jira.eon.com/browse/ONETP-3113
// this fix was inspired from https://github.com/auth0/auth0-angular/issues/463
function TranslateHttpLoaderFactory(
  httpHandler: HttpBackend
): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    { provide: TitleStrategy, useClass: MultiLanguageTitleStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        EnvironmentService,
        GoogleTagManagerConfiguration,
        Sentry.TraceService,
        IconInitializerService,
      ],
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    provideAuth0({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [
          `${environment.apiUrl}/*`,
          `${environment.servicePortalApiUrl}/*`,
        ],
      },
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    }),

    provideHttpClient(
      withInterceptors([loadingInterceptor, authHttpInterceptorFn])
    ),
    importProvidersFrom(ApiModule.forRoot({ rootUrl: environment.apiUrl })),
    MatDatepickerModule,
    provideAnimationsAsync(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: TranslateHttpLoaderFactory,
          deps: [HttpBackend],
        },
        defaultLanguage: DEFAULT_APP_LANGUAGE.localeCulture,
      }),
      MatDatepickerModule,
      MatNativeDateModule
    ),
  ],
};
