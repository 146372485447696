<div class="dialog">
  <div class="dialog__header">
    <h2 class="dialog__headline">
      {{ 'REQUESTS_DETAILS.DOCUMENT_DELETE_DIALOG_TITLE' | translate }}
    </h2>
    <dso-icon-button
      mat-dialog-close
      icon="close"
      size="medium"
      [altText]="'COMMON.CANCEL' | translate" />
  </div>

  <mat-dialog-content>
    {{ 'REQUESTS_DETAILS.DOCUMENT_DELETE_DIALOG_TEXT' | translate }}
  </mat-dialog-content>

  <div class="dialog__footer">
    <mat-dialog-actions align="end">
      <one-button
        size="medium"
        mat-dialog-close
        variant="secondary"
        (click)="dialogRef.close(false)">
        {{ 'COMMON.CANCEL' | translate }}
      </one-button>
      <one-button type="submit" size="medium" (click)="dialogRef.close(true)">
        {{ 'REQUESTS_DETAILS.DOCUMENT_DELETE_DIALOG_DELETE' | translate }}
      </one-button>
    </mat-dialog-actions>
  </div>
</div>
