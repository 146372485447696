<button
  class="icon-button"
  [ngClass]="{ 'icon-button--disabled': disabled }"
  [disabled]="disabled"
  (click)="emitClickEvent()">
  <span class="icon-button__text">
    <ng-content />
  </span>
  <mat-icon
    [ngClass]="{
      'color-disabled': disabled,
      'icon-button--scale-045': size === 'small',
      'icon-button--scale-065': size === 'medium',
      'icon-button--scale-085': size === 'big',
      'material-symbols-outlined': !customIcon,
    }"
    [attr.aria-label]="altText"
    [svgIcon]="customIcon ? icon : ''"
    [fontIcon]="!customIcon ? icon : ''" />
</button>
