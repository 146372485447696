@let _document = document();
<div class="document-container">
  <div class="document-metadata">
    <div class="metadata-row-1">
      <mat-icon
        class="document-icon dso-icon--small"
        svgIcon="file"
        [attr.aria-label]="
          'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate
        " />
      <span class="document-name">{{ _document.name }}</span>
    </div>

    <div class="metadata-row-2">
      <span>{{
        _document.createdAt | date: 'shortDate' : '' : currentLanguageCulture()
      }}</span>
      <span class="dot-separator"></span>
      <span>{{
        _document.createdAt | date: 'HH:mm:ss' : '' : currentLanguageCulture()
      }}</span>
      <span class="dot-separator"></span>
      <span>{{ _document.createdByUserFullName }}</span>
      <span class="dot-separator"></span>
      <span>{{ _document.size | fileSize: currentLanguage() }}</span>
    </div>
  </div>

  <div class="document-actions">
    <dso-icon-button
      icon="delete"
      size="big"
      [customIcon]="false"
      [matTooltip]="'REQUESTS_DETAILS.DELETE_DOCUMENT' | translate"
      [altText]="'REQUESTS_DETAILS.DELETE_DOCUMENT' | translate"
      (click)="openDeleteDocumentDialog()" />

    <dso-icon-button
      icon="download"
      size="big"
      [matTooltip]="'REQUESTS_DETAILS.DOWNLOAD_DOCUMENT' | translate"
      [altText]="'REQUESTS_DETAILS.DOWNLOAD_DOCUMENT' | translate"
      (click)="downloadDocument()" />
  </div>
</div>
