import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export type ButtonIcons =
  | 'arrow_right'
  | 'download'
  | 'save'
  | 'close'
  | 'cached'
  | 'delete';
@Component({
  selector: 'dso-icon-button',
  standalone: true,
  imports: [MatIconModule, NgClass],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
})
export class IconButtonComponent {
  @Input() public altText: string = 'Button';
  @Input() public icon: ButtonIcons = 'arrow_right';
  @Input() public size: 'small' | 'medium' | 'big' = 'small';
  @Input() public disabled: boolean = false;
  @Input() public customIcon: boolean = true;

  @Output() private clicked = new EventEmitter<void>();

  public emitClickEvent(): void {
    this.clicked.emit();
  }
}
