import { Component, inject } from '@angular/core';
import { IconButtonComponent } from '@app/shared';
import {
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@eon-one/one-ui';
@Component({
  selector: 'dso-delete-document-dialog',
  standalone: true,
  imports: [
    IconButtonComponent,
    MatDialogContent,
    MatDialogModule,
    TranslateModule,
    ButtonComponent,
  ],
  templateUrl: './delete-document-dialog.component.html',
  styleUrl: './delete-document-dialog.component.scss',
})
export class DeleteDocumentDialogComponent {
  readonly dialogRef = inject(MatDialogRef<DeleteDocumentDialogComponent>);
}
