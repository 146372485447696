import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[dsoDragAndDropUpload]',
  standalone: true,
})
export class DragAndDropUploadDirective {
  // Applies a CSS class when the user drags documents over the element
  @HostBinding('class.document-over') documentOver = false;

  // Emits the documents dropped into the element
  @Output() documentDropped = new EventEmitter<File[]>();

  // Listener for when documents are dragged over the element
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.documentOver = true;
  }

  // Listener for when the drag leaves the element
  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.documentOver = false;
  }

  // Listener for when documents are dropped onto the element
  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.documentOver = false;

    const documents = Array.from(event.dataTransfer?.files || []);
    if (documents.length) {
      this.documentDropped.emit(documents);
    }
  }
}
