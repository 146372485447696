import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  inject,
  OnInit,
  computed,
  Signal,
  WritableSignal,
  signal,
  DestroyRef,
  input,
} from '@angular/core';
import { DragAndDropUploadDirective } from '../drag-and-drop-upload.directive';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ConnectionRequestsAdditionalDocumentsService } from 'src/api/dso-portal/generated/services';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  forkJoin,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  acceptedDocumentTypes,
  getLanguageLocaleCulture,
  MAX_DOCUMENT_SIZE_BYTES,
  SnackBarConfigFactory,
  acceptedDocumentTypesString,
} from '@app/core/utils';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UploadedDocumentComponent } from './uploaded-document/uploaded-document.component';
import { PendingDocumentComponent } from './pending-document/pending-document.component';
import {
  AdditionalDocumentService,
  DocumentUploadErrorMapperService,
  ErrorHandlingService,
} from '@app/core/services';
import { FileSizePipe } from '@app/shared';

export interface PendingDocumentDetails {
  document: File;
  name: string;
  size: number;
  uploadOngoing: boolean;
  errors: string[];
  uploadProgress?: number;
  retryAllowed?: boolean;
}

export interface DocumentDetails {
  id: string;
  name: string;
  createdAt: string;
  createdByUserFullName: string;
  size: number;
}

@Component({
  selector: 'dso-documents-upload',
  standalone: true,
  imports: [
    CommonModule,
    DragAndDropUploadDirective,
    UploadedDocumentComponent,
    PendingDocumentComponent,
    TranslateModule,
  ],
  providers: [FileSizePipe],
  templateUrl: './documents-upload.component.html',
  styleUrl: './documents-upload.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsUploadComponent implements OnInit {
  connectionRequestId = input.required<string>();

  readonly #connectionRequestsAdditionalDocumentsService = inject(
    ConnectionRequestsAdditionalDocumentsService
  );
  readonly #snackBar = inject(MatSnackBar);
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  readonly #documentUploadErrorMapperService = inject(
    DocumentUploadErrorMapperService
  );
  readonly #fileSizePipe = inject(FileSizePipe);
  readonly #additionalDocumentService = inject(AdditionalDocumentService);

  public refreshDocumentListTrigger$ = new BehaviorSubject<void>(undefined); // used to refresh document list after document upload/delete
  public documents: File[] = [];
  public pendingDocumentList: WritableSignal<PendingDocumentDetails[]> = signal(
    []
  );
  public documentList$: Observable<DocumentDetails[]> | undefined;
  public readonly currentLanguage: Signal<string | undefined> = toSignal(
    this.#translateService.onLangChange.pipe(
      map(languageDetails => languageDetails.lang),
      startWith(this.#translateService.currentLang)
    )
  );

  public readonly currentLanguageCulture: Signal<string> = computed(() =>
    getLanguageLocaleCulture(this.currentLanguage()!)
  );

  ngOnInit(): void {
    this.documentList$ = this.#getDocumentList();
  }

  #getDocumentList(): Observable<DocumentDetails[]> {
    return this.refreshDocumentListTrigger$.pipe(
      switchMap(() =>
        this.#connectionRequestsAdditionalDocumentsService
          .getAdditionalDocuments({
            connectionRequestId: this.connectionRequestId(),
            expand: ['createdByUser'],
          })
          .pipe(
            map(additionalDocumentsData =>
              additionalDocumentsData.data.map(
                ({ id, name, createdAt, createdByUser, size }) => {
                  return {
                    id,
                    name,
                    createdAt,
                    createdByUserFullName: `${createdByUser?.firstName} ${createdByUser?.lastName}`,
                    size,
                  };
                }
              )
            ),
            catchError(error => {
              this.#errorHandlingService.handleError(error, {
                shouldRedirect: false,
                showErrorSnackbar: true,
              });
              return EMPTY;
            })
          )
      )
    );
  }

  public documentBrowseHandler(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const documentsArray: File[] = Array.from(input.files);
      this.uploadDocuments(documentsArray);
    }
  }

  // we don't use push, pop, etc on signals as they are immutable
  #updateDocumentInPendingDocumentList(
    documentName: string,
    newData: Partial<
      Pick<
        PendingDocumentDetails,
        'uploadOngoing' | 'uploadProgress' | 'errors' | 'retryAllowed'
      >
    >
  ): void {
    this.pendingDocumentList.update(documentList => {
      return documentList.map(document =>
        document.name === documentName ? { ...document, ...newData } : document
      );
    });
  }

  public uploadDocuments(documents: File[]): void {
    const documentRequests$ = documents.map(document => {
      const documentDetails: PendingDocumentDetails = {
        document,
        name: document.name,
        size: document.size,
        uploadOngoing: true,
        uploadProgress: 0,
        errors: [],
      };

      const documentErrors = this.#getDocumentErrors(document);
      documentDetails.errors = documentErrors;

      this.pendingDocumentList.update(uploadFailedDocumentList => [
        ...uploadFailedDocumentList,
        documentDetails,
      ]);

      // before upload, check if size and type meet the requirements
      if (documentErrors.length) {
        this.#updateDocumentInPendingDocumentList(document.name, {
          uploadOngoing: false,
        });
        return EMPTY;
      }

      return this.#additionalDocumentService
        .uploadDocument(this.connectionRequestId(), document)
        .pipe(
          tap({
            next: uploadProgress =>
              this.#updateDocumentInPendingDocumentList(document.name, {
                uploadProgress,
              }),
            complete: () => this.#handleUploadSuccess(document.name),
          }),
          finalize(() =>
            this.#updateDocumentInPendingDocumentList(document.name, {
              uploadOngoing: false,
            })
          ),
          catchError(error => {
            const errorCode = error?.error?.code;
            const errorType = error?.error?.errors?.[0]?.type;
            this.#updateDocumentInPendingDocumentList(document.name, {
              retryAllowed:
                this.#documentUploadErrorMapperService.canRetryUpload(
                  errorCode
                ),
              errors: [
                ...documentDetails.errors,
                this.#documentUploadErrorMapperService.getErrorMessage(
                  errorCode,
                  errorType
                ),
              ],
            });

            this.#errorHandlingService.handleError(error, {
              shouldRedirect: false,
              showErrorSnackbar: true,
              msgTranslationIdentifier: 'SNACKBAR.UPLOAD_GENERIC_ERROR_MESSAGE',
              translationInterpolateParams: { documentName: document.name },
            });
            return EMPTY;
          })
        );
    });

    forkJoin(documentRequests$)
      .pipe(
        finalize(() => this.refreshDocumentListTrigger$.next()),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  #handleUploadSuccess(documentName: string): void {
    this.pendingDocumentList.update(uploadFailedDocumentList =>
      uploadFailedDocumentList.slice(0, -1)
    );
    this.#snackBar.open(
      this.#translateService.instant(
        'REQUESTS_DETAILS.DOCUMENT_UPLOAD_SUCCESS',
        { documentName }
      ),
      'X',
      SnackBarConfigFactory.build(['snack-bar-success'])
    );
  }

  #getDocumentErrors(document: File): string[] {
    const documentErrors: string[] = [];
    if (document.size > MAX_DOCUMENT_SIZE_BYTES) {
      const sizeErrorMessage = this.#translateService.instant(
        'REQUESTS_DETAILS.DOCUMENT_UPLOAD_VALIDATION_MAX_SIZE',
        {
          maxSize: this.#fileSizePipe.transform(
            MAX_DOCUMENT_SIZE_BYTES,
            this.currentLanguage()!
          ),
        }
      );
      documentErrors.push(sizeErrorMessage);
    }

    const hasFileTypeError = !acceptedDocumentTypes
      .map(fileType => fileType.type)
      .includes(document.type);

    if (hasFileTypeError) {
      const typeErrorMessage = this.#translateService.instant(
        'REQUESTS_DETAILS.DOCUMENT_UPLOAD_VALIDATION_TYPE',
        {
          acceptedTypes: acceptedDocumentTypesString,
        }
      );
      documentErrors.push(typeErrorMessage);
    }
    return documentErrors;
  }

  public unselectDocument(documentIndexToBeRemoved: number): void {
    const updatedList = this.pendingDocumentList().filter(
      (_, index) => index !== documentIndexToBeRemoved
    );
    this.pendingDocumentList.set(updatedList);
    this.refreshDocumentListTrigger$.next();
  }
}
