/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteAdditionalDocument } from '../fn/connection-requests-additional-documents/delete-additional-document';
import { DeleteAdditionalDocument$Params } from '../fn/connection-requests-additional-documents/delete-additional-document';
import { downloadAdditionalDocument } from '../fn/connection-requests-additional-documents/download-additional-document';
import { DownloadAdditionalDocument$Params } from '../fn/connection-requests-additional-documents/download-additional-document';
import { getAdditionalDocuments } from '../fn/connection-requests-additional-documents/get-additional-documents';
import { GetAdditionalDocuments$Params } from '../fn/connection-requests-additional-documents/get-additional-documents';
import { ResponseWrapperListAdditionalDocumentDto } from '../models/response-wrapper-list-additional-document-dto';
import { StreamingResponseBody } from '../models/streaming-response-body';
import { uploadAdditionalDocument } from '../fn/connection-requests-additional-documents/upload-additional-document';
import { UploadAdditionalDocument$Params } from '../fn/connection-requests-additional-documents/upload-additional-document';


/**
 * This API is used to get additional documents of connection requests. Additional documents are documents which have been uploaded to a connection request after the request has been submitted.
 */
@Injectable({ providedIn: 'root' })
export class ConnectionRequestsAdditionalDocumentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAdditionalDocuments()` */
  static readonly GetAdditionalDocumentsPath = '/connection-requests/{connectionRequestId}/additional-documents';

  /**
   * Metadata list of additional documents.
   *
   * Returns the metadata of the additional documents of a connection request. Sorted by createdAt descending. Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdditionalDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalDocuments$Response(params: GetAdditionalDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseWrapperListAdditionalDocumentDto>> {
    return getAdditionalDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Metadata list of additional documents.
   *
   * Returns the metadata of the additional documents of a connection request. Sorted by createdAt descending. Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdditionalDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalDocuments(params: GetAdditionalDocuments$Params, context?: HttpContext): Observable<ResponseWrapperListAdditionalDocumentDto> {
    return this.getAdditionalDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseWrapperListAdditionalDocumentDto>): ResponseWrapperListAdditionalDocumentDto => r.body)
    );
  }

  /** Path part for operation `uploadAdditionalDocument()` */
  static readonly UploadAdditionalDocumentPath = '/connection-requests/{connectionRequestId}/additional-documents';

  /**
   * Upload an additional document to a connection request.
   *
   * Allowed file types: application/pdf, image/jpeg, image/png
   *          Allowed permissions: **custom/connectionRequest/additionalDocuments/manage**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAdditionalDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdditionalDocument$Response(params: UploadAdditionalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadAdditionalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an additional document to a connection request.
   *
   * Allowed file types: application/pdf, image/jpeg, image/png
   *          Allowed permissions: **custom/connectionRequest/additionalDocuments/manage**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAdditionalDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdditionalDocument(params: UploadAdditionalDocument$Params, context?: HttpContext): Observable<void> {
    return this.uploadAdditionalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadAdditionalDocument()` */
  static readonly DownloadAdditionalDocumentPath = '/connection-requests/{connectionRequestId}/additional-documents/{documentId}/download';

  /**
   * Download additional document.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAdditionalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAdditionalDocument$Response(params: DownloadAdditionalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<StreamingResponseBody>> {
    return downloadAdditionalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download additional document.
   *
   * Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAdditionalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAdditionalDocument(params: DownloadAdditionalDocument$Params, context?: HttpContext): Observable<StreamingResponseBody> {
    return this.downloadAdditionalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<StreamingResponseBody>): StreamingResponseBody => r.body)
    );
  }

  /** Path part for operation `deleteAdditionalDocument()` */
  static readonly DeleteAdditionalDocumentPath = '/connection-requests/{connectionRequestId}/additional-documents/{documentId}';

  /**
   * Delete an additional document.
   *
   * Allowed permissions: **custom/connectionRequest/additionalDocuments/manage**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdditionalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdditionalDocument$Response(params: DeleteAdditionalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAdditionalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an additional document.
   *
   * Allowed permissions: **custom/connectionRequest/additionalDocuments/manage**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdditionalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdditionalDocument(params: DeleteAdditionalDocument$Params, context?: HttpContext): Observable<void> {
    return this.deleteAdditionalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
