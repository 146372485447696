<div
  class="drag-and-drop-container"
  dsoDragAndDropUpload
  (documentDropped)="uploadDocuments($event)">
  <input
    type="file"
    id="document-drop-ref"
    multiple
    (change)="documentBrowseHandler($event)" />
  <p>{{ 'REQUESTS_DETAILS.DRAG_AND_DROP_DOCUMENT' | translate }}</p>
  &nbsp;
  <label for="document-drop-ref" class="document-selector">
    {{ 'REQUESTS_DETAILS.SELECT_FILE' | translate }}
  </label>
</div>

@for (document of pendingDocumentList(); track $index) {
  <dso-pending-document
    [currentLanguage]="currentLanguage()!"
    [connectionRequestId]="connectionRequestId()"
    [index]="$index"
    [documentDetails]="document"
    (unselectDocument)="unselectDocument($event)" />
}

@for (document of documentList$ | async; track document.id) {
  <dso-uploaded-document
    [currentLanguage]="currentLanguage()!"
    [currentLanguageCulture]="currentLanguageCulture()"
    [connectionRequestId]="connectionRequestId()"
    [refreshDocumentListTrigger$]="refreshDocumentListTrigger$"
    [document]="document" />
}
