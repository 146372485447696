import { inject, Injectable } from '@angular/core';
import { InterpolationParameters, TranslateService } from '@ngx-translate/core';
import { acceptedDocumentTypesString } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadErrorMapperService {
  readonly #translateService = inject(TranslateService);

  readonly #documentErrorList = [
    {
      code: 409,
      type: 'ADDITIONAL_DOCUMENT_NAME_CONFLICT_ERROR',
      translationKey: 'ERROR.ADDITIONAL_DOCUMENT_NAME_CONFLICT_ERROR',
    },
    {
      code: 413,
      type: 'ADDITIONAL_DOCUMENT_TOTAL_SIZE_EXCEEDED_ERROR',
      translationKey: 'ERROR.ADDITIONAL_DOCUMENT_TOTAL_SIZE_EXCEEDED_ERROR',
    },
    {
      code: 400,
      type: 'CONNECTION_REQUEST_ALREADY_ARCHIVED_ERROR',
      translationKey: 'ERROR.CONNECTION_REQUEST_ALREADY_ARCHIVED_ERROR',
    },
    {
      code: 400,
      type: 'VALIDATION_ERROR',
      translationKey: 'REQUESTS_DETAILS.DOCUMENT_UPLOAD_VALIDATION_TYPE',
    },
  ];

  readonly #errorMap = new Map(
    this.#documentErrorList.map(error => [
      `${error.code}_${error.type}`,
      error.translationKey,
    ])
  );

  #getAllErrorCodes(): number[] {
    return Array.from(
      new Set(this.#documentErrorList.map(error => error.code))
    );
  }

  public getErrorMessage(code: number, type: string): string {
    const key = `${code}_${type}`;
    const errorTranslationKey =
      this.#errorMap.get(key) ?? 'ERROR.UNEXPECTED.CONTENT';
    let interpolateParams: InterpolationParameters | undefined;

    if (key === '400_VALIDATION_ERROR') {
      interpolateParams = {
        acceptedTypes: acceptedDocumentTypesString,
      };
    }

    return `${this.#translateService.instant(
      'ERROR.DOCUMENT_UPLOAD_ERROR'
    )} ${this.#translateService.instant(
      errorTranslationKey,
      interpolateParams
    )}`;
  }

  public canRetryUpload(code: number): boolean {
    return !this.#getAllErrorCodes().includes(code);
  }
}
