import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DialogConfigFactory,
  FileSizePipe,
  IconButtonComponent,
} from '@app/shared';
import { BehaviorSubject, filter, switchMap } from 'rxjs';

import {
  DownloadUrlCreatorService,
  ErrorHandlingService,
} from '@app/core/services';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ConnectionRequestsAdditionalDocumentsService } from 'src/api/dso-portal/generated/services';
import { DocumentDetails } from '../documents-upload.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarConfigFactory } from '@app/core/utils';
import {
  InterpolationParameters,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDocumentDialogComponent } from './delete-document-dialog/delete-document-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dso-uploaded-document',
  standalone: true,
  imports: [
    CommonModule,
    IconButtonComponent,
    MatIconModule,
    FileSizePipe,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './uploaded-document.component.html',
  styleUrl: './uploaded-document.component.scss',
})
export class UploadedDocumentComponent {
  @Input() refreshDocumentListTrigger$ = new BehaviorSubject<void>(undefined);
  currentLanguage = input.required<string>();
  currentLanguageCulture = input.required<string>();
  connectionRequestId = input.required<string>();
  document = input.required<DocumentDetails>();

  readonly #connectionRequestsAdditionalDocumentsService = inject(
    ConnectionRequestsAdditionalDocumentsService
  );
  readonly #downloadUrlCreatorService = inject(DownloadUrlCreatorService);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  readonly #snackBar = inject(MatSnackBar);
  readonly #translateService = inject(TranslateService);
  readonly #dialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);

  public openDeleteDocumentDialog(): void {
    const dialogRef = this.#dialog.open(
      DeleteDocumentDialogComponent,
      DialogConfigFactory.build()
    );
    dialogRef
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() =>
          this.#connectionRequestsAdditionalDocumentsService.deleteAdditionalDocument(
            {
              connectionRequestId: this.connectionRequestId(),
              documentId: this.document().id,
            }
          )
        ),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: () => {
          this.#snackBar.open(
            this.#translateService.instant(
              'REQUESTS_DETAILS.DOCUMENT_DELETE_SUCCESS',
              { documentName: this.document().name }
            ),
            'X',
            SnackBarConfigFactory.build(['snack-bar-success'])
          );
          this.refreshDocumentListTrigger$.next();
        },
        error: error =>
          this.#showDocumentActionError(
            error,
            'REQUESTS_DETAILS.DOCUMENT_DELETE_FAILED',
            { documentName: this.document().name }
          ),
      });
  }

  public downloadDocument(): void {
    this.#connectionRequestsAdditionalDocumentsService
      .downloadAdditionalDocument({
        connectionRequestId: this.connectionRequestId(),
        documentId: this.document().id,
      })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: file =>
          this.#downloadUrlCreatorService.create(
            file as Blob,
            this.document().name
          ),
        error: error =>
          this.#showDocumentActionError(
            error,
            'SNACKBAR.DOWNLOAD_ERROR_MESSAGE'
          ),
      });
  }

  #showDocumentActionError(
    error: HttpErrorResponse,
    messageKey: string,
    translationInterpolateParams?: InterpolationParameters
  ): void {
    this.#errorHandlingService.handleError(error, {
      shouldRedirect: false,
      showErrorSnackbar: true,
      msgTranslationIdentifier: messageKey,
      translationInterpolateParams,
    });
  }
}
